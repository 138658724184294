import React from "react";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import styled from "styled-components";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import usePopupActions, {
  GUEST_LOGIN_POPUP,
  PRO_REGISTER_POPUP,
} from "@/store/actions/popupAction";
import { useRouter } from "next/router";
import { Button } from "@/outscal-commons-frontend/Styled/Buttons";
import { shine } from "@/outscal-commons-frontend/Styled/Animations";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import { GradientText, Text } from "@/outscal-commons-frontend/Styled/TextStyles";

const StyledButton = styled(Button)`
  padding: 10px 18px;
  font-size: ${(p) => p.theme.fontSize.lg};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  margin-right: 12px;
  background-color: ${(p) => p.theme.colors.themeBlue};
  &:hover {
    animation-name: ${shine("#3f57cb", "#b3cfff", "#e7f0ff")};
    animation-duration: 280ms;
    background-color: ${(p) => p.theme.colors.themeBlue};
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}px) {
    padding: 8px 12px;
    font-size: ${(p) => p.theme.fontSize.sm};
  }
`;

const NonLoggedInAvatar = () => {
  const router = useRouter();
  const { showPopup } = usePopupActions();

  const ShowRegisterPopup = () => {
    if (
      router.asPath.includes("/course") ||
      router.asPath.includes("scholarship")
    ) {
      showPopup(GUEST_LOGIN_POPUP, {
        heading: (
          <RowDiv alignItems="center" gap="8px">
            Unlock PRO
            <ProTag fontSize="48px" />
          </RowDiv>
        ),
        subHeading: (
          <>
            Sign up and Unlock PRO{" "}
            <ProTag fontSize="18px" padding="3px 0 0 0" /> benefits for
            <GradientText
              fontWeight="semiBold"
              colors={["themeBlue", "discordBlue", "themeBlue"]}
              style={{ display: "inline-block" }}
            >
              &nbsp;FREE!
            </GradientText>
          </>
        ),
      });
    } else {
      showPopup(PRO_REGISTER_POPUP);
    }
  };

  return (
    <RowDiv alignItems="center" gap="16px">
      <StyledButton
        onClick={() => {
          ShowRegisterPopup();
        }}
        data-analytics={[UIElements.BUTTON, "JOIN_FOR_FREE_CTA"]}
      >
        <RowDiv alignItems="center" gap="8px">
          <ProTag fontSize="24px" />
          <Text fontSize="md" color="white">
            Unlock PRO for FREE
          </Text>
        </RowDiv>
      </StyledButton>
    </RowDiv>
  );
};

export default NonLoggedInAvatar;
