import React from "react";
import styled from "styled-components";
import ProfileContent from "./ProfileContent";
import DrawerMenu from "./DrawerMenu";

const Root = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw + 8px);
  backdrop-filter: blur(3px);
  visibility: ${(p) => (p.isVisible ? "visible" : "hidden")};
  transition: visibility 0.3s ease-in-out;
  background-color: #6e6e6e52;
  z-index: 20;
  top: 0;
  left: -8px;
`;

const Drawer = styled.div`
  width: 50%;
  border-radius: 0px ${(p) => p.theme.borderRadius.xxs}
    ${(p) => p.theme.borderRadius.xxs} 0px;
  height: 100%;
  background-color: ${(p) => p.theme.colors.white};
  transform: ${(p) => `translateX(${p.isVisible ? `0px` : `-100vw`})`};
  transition: all 0.5s ease-in-out;
  overflow-x: hidden;
`;

function NavDrawer({ data, navDrawerOpen, setNavDrawerOpen }) {
  return (
    <Root
      onClick={(e) => {
        setNavDrawerOpen(false);
        e.stopPropagation();
      }}
      isVisible={navDrawerOpen}
    >
      <Drawer
        onClick={(e) => {
          e.stopPropagation();
        }}
        isVisible={navDrawerOpen}
      >
        {navDrawerOpen && (
          <>
            <ProfileContent />
            <DrawerMenu data={data} />
          </>
        )}
      </Drawer>
    </Root>
  );
}

export default NavDrawer;
