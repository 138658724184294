import React from "react";
import styled, { keyframes } from "styled-components";
import { BsDiscord } from "react-icons/bs";
import Constant from "@/staticData/constant";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  33.33% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
`;

const RotateDiscord = styled(BsDiscord)`
  animation: ${rotate} 2s infinite;
  cursor: pointer;
  color: ${(p) => p.theme.colors.discordBlue};
`;

const RotateDiscordIcon = ({
  inviteLink = Constant.OUTSCAL_DISCORD_SERVER,
}) => {
  const connectDiscord = () => {
    window.open(inviteLink, "_blank");
  };

  return (
    <RotateDiscord
      size="40px"
      onClick={connectDiscord}
      data-analytics={[UIElements.LINK, "Connect Discord"]}
    />
  );
};

export default RotateDiscordIcon;
