import Link from "next/link";
import React from "react";
import styled from "styled-components";

const Menu = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  display: none;
  padding: 8px;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.small};
`;

const Root = styled.div`
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: max-content;
  height: auto;
  &:hover ${Menu} {
    display: flex;
  }
`;

const Item = styled.div`
  padding: 8px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  &:hover {
    background-color: ${(p) => p.theme.colors.fadedYellow};
  }
  cursor: pointer;
`;

function SubMenu({ menu }) {
  return (
    <Root>
      <Menu>
        {Object.values(menu).map((item) => {
          return (
            <div key={item.id}>
              {item.link && (
                <Link href={item.link}>
                  <Item>{item.label}</Item>
                </Link>
              )}
              {item.action && (
                <Item
                  key={item.id}
                  onClick={() => {
                    item.action();
                  }}
                ></Item>
              )}
            </div>
          );
        })}
      </Menu>
    </Root>
  );
}

export default SubMenu;
