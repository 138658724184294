import React from "react";
import ScholarshipReminderPlugView from "./ScholarshipReminderPlug.view";
import useScholarshipActions from "@/store/actions/scholarshipAction";
import { sectionData } from "./ScholarshipReminderPlug.model";
import { useRouter } from "next/router";

const ScholarshipReminderPlugController = () => {
  const { scholarshipPlugInfo } = useScholarshipActions();
  const { cta } = sectionData;
  const router = useRouter();

  return (
    <ScholarshipReminderPlugView
      description={scholarshipPlugInfo?.plugMessage}
      cta={{ ...cta, link: scholarshipPlugInfo?.plugLink }}
      visibleInMobile={!router.pathname.includes("/jobs")}
    />
  );
};

export default ScholarshipReminderPlugController;
