import styled from "styled-components";
import { HiOutlinePencil } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { RowDiv } from "@/outscal-commons-frontend/Styled";

export const Title = styled.h1`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.fontSize.xxxxl};
  @media screen and (max-width: ${(p) => p.theme.breakpoints.sm}px) {
    gap: 10px;
    font-size: ${(p) => p.theme.fontSize.xxxl};
  }
`;

export const EditIcon = styled(HiOutlinePencil)`
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue};
`;

export const Root = styled.div`
  display: ${(p) => (p.showHeader ? `flex` : `none`)};
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: ${(p) => p.theme.colors.white};
  align-items: center;
  padding: 0 8px;
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  justify-content: space-between;
  margin-inline: auto;
  
  @media screen and (max-width: 600px) {
    ${(p) => p.isCoursesPage === true && `display:none`}
  }
`;

export const BurgerMenuLogo = styled(GiHamburgerMenu)`
  cursor: pointer;
  @media (min-width: ${(p) => p.theme.breakpoints.md}px) {
    display: none;
  }
`;

export const MenuItems = styled(RowDiv)`
  gap: 30px;
  @media (max-width: 991px) {
    display: none;
  }
`;
