import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "@/store/reducers/loadingReducer";

const useLoadingActions = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading);

  const setLoadingState = (state) => {
    dispatch(setLoading(state));
  };

  return { setLoadingState, loading };
};

export default useLoadingActions;
