import globals from "@/Globals";
import React from "react";
import styled from "styled-components";
import SubMenu from "./SubMenu";

const Root = styled.div`
  position: relative;
  cursor: pointer;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightGrey};
`;

function NavDrawerItem({ item }) {
  return (
    <Root
      onClick={() => {
        if (item.link) {
          window.location = item.link;
        }
      }}
    >
      {item.label}
      {item.subMenu && <SubMenu menu={item.subMenu}></SubMenu>}
    </Root>
  );
}

export default NavDrawerItem;
