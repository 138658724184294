import Link from "next/link";
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: max-content;
  height: auto;
`;

const Item = styled.div`
  padding: 8px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  &:hover {
    background-color: ${(p) => p.theme.colors.fadedYellow};
  }
  cursor: pointer;
`;

function SubMenu({ menu }) {
  return (
    <Root>
      {Object.values(menu).map((item) => {
        return (
          <Item
            key={item.id}
            onClick={() => {
              if (item.link) {
                window.location = process.env.REACT_APP_WEBSITE_URL + item.link;
              }
            }}
          >
            {item.label}
          </Item>
        );
      })}
    </Root>
  );
}

export default SubMenu;
