import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { H2, H3 } from "@/outscal-commons-frontend/Styled/TextStyles";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import React from "react";
import styled from "styled-components";
import Image from "next/image";

const Root = styled.div`
  height: auto;
  width: 100.1%;
  background-color: ${(p) => p.theme.colors.lightGrey};
`;

const Row = styled(RowDiv)`
  gap: 10px;
  padding: 15px;
  height: auto;
  align-items: center;
`;

const Col = styled(ColDiv)`
  text-align: left;
  gap: 10px;
`;

function ProfileContent() {
  const { user } = useUserActions();
  const greeting = `Hii ${
    user?.userJobProfile?.name ? user?.userJobProfile?.name : "Guest"
  }`;
  const welcomeText = user?.userJobProfile
    ? "Welcome back"
    : "Welcome to Outscal";

  return (
    <Root>
      <Row>
        <Image
          style={{
            objectFit: "contain",
            borderRadius: "50%",
            transition: "all 0.8s ease-in-out",
            cursor: "pointer",
          }}
          width={60}
          height={60}
          src={user?.userDetails?.profile_picture || ""}
          alt="Profile pic"
          quality={10}
        />
        <Col justifyContent={"space-between"}>
          <H2 fontSize={"md"}>{greeting}</H2>
          <H3 color={"textDarkGrey"} fontSize={"xs"}>
            {welcomeText}
          </H3>
        </Col>
      </Row>
    </Root>
  );
}

export default ProfileContent;
