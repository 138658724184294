import React from "react";
import dynamic from "next/dynamic";
import NavItem from "./NavItem";
import Link from "next/link";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import Avatar from "./Avatar";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
const OutscalAnimatedLogo = dynamic(() =>
  import("@/Components/OutscalAnimatedLogo")
);
import { BurgerMenuLogo, MenuItems } from "./style";
import Constant from "@/staticData/constant";
import styled from "styled-components";

const Root = styled(RowDiv)`
  width: 100%;
  min-height: 60px;
  height: ${Constant.NAVBAR_HEIGHT};
`;

function GameBuildNavBar({ data, setNavDrawerOpen }) {
  if (!data) {
    return null;
  }

  const hasNavList = data.navList && Object.keys(data.navList).length > 0;
  const hasProfileMenu =
    data.profileMenu && Object.keys(data.profileMenu).length > 0;

  return (
    <Root justifyContent="space-between" alignItems="center">
      <RowDiv alignItems="center" gap="30px">
        <MenuItems>
          <Link href="/">
            <OutscalAnimatedLogo />
          </Link>
          {hasNavList &&
            Object.values(data.navList).map((item) => (
              <NavItem key={item.id} item={item} />
            ))}
        </MenuItems>
        <BurgerMenuLogo
          onClick={() => {
            setNavDrawerOpen(true);
          }}
          color={Theme.colors.themeYellow}
          size="40px"
        />
      </RowDiv>
      {hasProfileMenu && <Avatar menu={data.profileMenu} />}
    </Root>
  );
}

export default GameBuildNavBar;
