import React from "react";
import styled, { keyframes } from "styled-components";
import Link from "next/link";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { MdOutlineArrowOutward } from "react-icons/md";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);   
  }
  100% {
    transform: scale(1);
  }
`;

const Root = styled(Link)`
  position: fixed;
  right: 30px;
  bottom: 10px;
  border: 1px solid ${(p) => p.theme.colors.borderGray};
  box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
  border-radius: ${(p) => p.theme.borderRadius.sm};
  padding: 10px 0;
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.bgLightBlue};
  color: ${(p) => p.theme.colors.black};
  animation: ${pulse} 2s infinite ease-in-out;
  @media screen and (max-width: 767px) {
    ${(p) => p.visibleInMobile === false && `display:none`};
    padding: 9px 8px 16px 8px;
    bottom: 40px;
    left: 0;
    right: 0;
    z-index: 4;
    width: calc(100% - 16px);
    background-color: ${(p) => p.theme.colors.themeBlue};
    border-radius: unset;
    animation: none;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (min-width: 1400px) {
    right: calc(47% - 686px);
  }
`;

const Description = styled.p`
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xs};
    color: ${(p) => p.theme.colors.white};
  }
`;

const CTA = styled.button`
  background-color: ${(p) => p.theme.colors.themeBlue};
  color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius.xs};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  border: 1px solid ${(p) => p.theme.colors.borderGray};
  padding: 5px 10px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const ArrowIcon = styled(MdOutlineArrowOutward)`
  display: none;
  color: ${(p) => p.theme.colors.white};
  @media screen and (max-width: 600px) {
    display: flex;
    height: 16px;
    width: 16px;
  }
`;

const ScholarshipReminderPlugView = ({ description, cta, visibleInMobile }) => {
  return (
    <Root
      href={cta?.link}
      visibleInMobile={visibleInMobile}
      data-analytics={[UIElements.LINK, `scholarship-reminder-plug`]}
    >
      <Description>{description}</Description>
      <ArrowIcon />
      <Link href={cta.link}>
        <CTA>{cta.text}</CTA>
      </Link>
    </Root>
  );
};

export default ScholarshipReminderPlugView;
