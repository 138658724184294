import React from "react";
import styled from "styled-components";
import Link from "next/link";
import SubMenu from "./SubMenu";
import {
  CommonElements,
  UIElements,
} from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";

const Root = styled.div`
  position: relative;
`;

const LinkWrap = styled(Div)`
  text-align: center;
`;

const MetaText = styled(Div)`
  font-size: ${(p) => p.theme.fontSize.xs};
  color: ${(p) => p.theme.colors.purple};
`;

function NavItem({ item }) {
  return (
    <Root>
      {item.subMenu && <span key={item.id}>{item.label}</span>}
      {item.subMenu && <SubMenu menu={item.subMenu}></SubMenu>}
      {item.link && (
        <LinkWrap>
          <Link href={item.link} target={item.target}>
            <span
              data-analytics={[
                UIElements.LINK,
                item.label,
                CommonElements.HEADER,
              ]}
            >
              {item.label}
            </span>
          </Link>
          {item?.meta && <MetaText>{item?.meta?.text}</MetaText>}
        </LinkWrap>
      )}
      {item.action && (
        <span
          key={item.id}
          onClick={() => {
            item.action();
          }}
          data-analytics={[UIElements.LINK, item.label, CommonElements.HEADER]}
        >
          {item.label}
        </span>
      )}
    </Root>
  );
}

export default NavItem;
