export const checkIfFtueValueCaptureIsFilled = (userDetails = {}) => {
  const { userJobProfile } = userDetails;
  if (
    !userJobProfile ||
    userJobProfile?.is_working_professional === undefined ||
    userJobProfile?.compilers_intent === undefined
  ) {
    return false;
  }

  return true;
};

export const checkIfFtue1IsFilled = (userDetails = {}) => {
  const { userJobProfile } = userDetails;
  if (
    !userJobProfile ||
    !userJobProfile?.name ||
    userJobProfile?.is_working_professional === undefined
  ) {
    return false;
  }

  return true;
};

export const checkIfToShowFtue1 = (userDetails) => {
  const { userJobProfile, userPreference } = userDetails;

  if (
    !userJobProfile ||
    !userJobProfile?.name ||
    userJobProfile?.is_working_professional === undefined ||
    userJobProfile?.game_engine_experience === undefined ||
    !userJobProfile?.job_role ||
    !userJobProfile.persona_v2
  ) {
    return true;
  }

  if (userJobProfile?.is_working_professional) {
    if (
      !userJobProfile?.professional_background ||
      userJobProfile?.experience < 0 ||
      userJobProfile?.designations?.length <= 0
    ) {
      return true;
    }
  } else {
    if (userPreference?.designations?.length <= 0) {
      return true;
    }
  }

  return false;
};

export const getCurrentFtueStep = (userDetails) => {
  try {
    let userProfile = userDetails?.userJobProfile;
    let userJobPreference = userDetails?.userPreference;
    let currentStepPending = null;

    if (!checkIfFtue1IsFilled(userDetails)) {
      currentStepPending = 1;
    } else if (
      !(
        userProfile &&
        userProfile?.time_to_join !== null &&
        userProfile?.time_to_join >= 0 &&
        userProfile?.annual_compensation &&
        Object.keys(userProfile?.annual_compensation)?.length > 0 &&
        userJobPreference &&
        userJobPreference?.annual_compensation &&
        Object.keys(userJobPreference?.annual_compensation)?.length > 0
      )
    ) {
      currentStepPending = 3;
    }

    return currentStepPending;
  } catch (err) {
    console.log(err);
  }
};
