import React from "react";
import styled from "styled-components";
import NavDrawerItem from "./NavDrawerItem";

const Root = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

function DrawerMenu({ data }) {
  return (
    <Root>
      {Object.values(data?.navList || []).map((item) => {
        return <NavDrawerItem key={item.id} item={item} />;
      })}
    </Root>
  );
}

export default DrawerMenu;
